'use client';

import { useMemo, useOptimistic, useTransition } from 'react';
import { StandardCardsGrid } from '../StandardCardsGrid';
import { DEFAULT_ROOT_CATEGORIES_SLUG } from '@/libs/utils/constants';
import { Badge } from '@/libs/api/arena-data';
import { ShortGameModelFromFeed } from '@/games';
import { usePathname, useRouter } from 'next/navigation';
import styles from './styles.module.css';
import classNames from 'classnames';
import { THorizontalPills, TPill } from '@/components/HorizontalPills/model';
import HorizontalPills from '../HorizontalPills';
import { Dropdown } from '../Dropdown';
import React from 'react';
import { useIsDesktop } from '@/libs/utils/useIsDesktop';
import { useScreenSize } from '@/libs/utils/useScreensize';
import { ALL_CATEGORY } from '@/libs/utils/constants';
import { getLocaleFromPathname } from '@/libs/utils/url';
import { EAriaCurrentEnum } from '@/components/Pill/model';
export const HeadlineCardsGrid = (props: {
  feed: ShortGameModelFromFeed[];
  rootCategorySlug?: string | null;
  initialFilter?: string | null;
  title?: string | null;
  query?: string | null;
  hideCategories?: boolean;
  arenaBadges?: Badge[];
  allCategories?: string[];
  sortOptions?: string[];
  filterConfig?: THorizontalPills;
  isSearchPage?: boolean;
  adsDivider?: boolean;
  adsInterval?: number;
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const [isPending, startTransition] = useTransition();
  const screenSize = useScreenSize();
  const [currentFilter, setCurrentFilter] = useOptimistic<string | undefined | null>(props?.initialFilter ?? ALL_CATEGORY.name);
  const games = props?.feed ?? [];
  const locale = getLocaleFromPathname(pathname);
  const categories = useMemo(() => {
    if (props?.hideCategories) {
      return [];
    }
    return props?.allCategories ?? [];
  }, [props.hideCategories, props.allCategories]);
  function filterFeed(selectedCategory: string, href: string) {
    startTransition(() => {
      setCurrentFilter(selectedCategory);
      router.push(`/${locale}${href}`);
    });
  }
  const isDesktop = useIsDesktop();
  const onSelect = (pill: TPill) => {
    filterFeed(pill.label, pill.href);
  };
  const horizontalPillsComponent = (() => {
    if (props.hideCategories || !props.filterConfig) {
      return null;
    }
    const config = {
      ...props.filterConfig,
      onSelect: onSelect,
      pills: props.filterConfig.pills.map(pill => ({
        ...pill,
        isActive: pill.label.toLowerCase() === currentFilter?.toLowerCase()
      })),
      ariaCurrent: EAriaCurrentEnum.Page
    };
    return categories.length > 0 ? <HorizontalPills config={config} index={0} isPillsFromPages isSearchPage={props.isSearchPage} /> : null;
  })();
  const onSortFeed = (by: string) => {
    startTransition(() => {
      startTransition(() => {
        const params = new URLSearchParams(window.location.search);
        params.set('sort', by);
        router.push(`${pathname}?${params.toString()}`);
      });
    });
  };
  return <>
			<div className={classNames(styles.header, 'headers')}>
				<h2 className={styles.headline}>
					{props.title} {props?.query && <span className={styles.query}>“{props.query}”</span>}
				</h2>
				{props.sortOptions?.length && <Dropdown items={props.sortOptions} onUpdate={(e: string) => {
        onSortFeed(e);
      }} />}
				{!isDesktop && horizontalPillsComponent}
			</div>
			{isDesktop && horizontalPillsComponent}
			{games.length > 0 && <StandardCardsGrid games={games} rootCategorySlug={props?.rootCategorySlug ?? DEFAULT_ROOT_CATEGORIES_SLUG} arenaBadges={props?.arenaBadges ?? []} screenSize={screenSize} isSearchPage={props?.isSearchPage} isPending={isPending} adsDivider={props.adsDivider ?? false} adsInterval={props.adsInterval ?? 0} />}
		</>;
};