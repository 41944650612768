export type TPill = {
	index?: number;
	pillType: string;
	label: string;
	prefix: string;
	selected?: boolean;
	target?: string;
	leftImg?: string;
	rightImg?: string;
	override_border_color?: string;
	override_border_color_hover?: string;
	override_border_color_focus?: string;
	override_border_color_active?: string;
	override_use_border?: boolean;
	override_use_border_hover?: boolean;
	override_use_border_focus?: boolean;
	override_use_border_active?: boolean;
	override_label_color?: string;
	override_label_color_hover?: string;
	override_label_color_focus?: string;
	override_label_color_active?: string;
	override_background_color?: string;
	override_background_color_end?: string;
	override_background_color_hover?: string;
	override_background_color_hover_end?: string;
	override_background_color_focus?: string;
	override_background_color_focus_end?: string;
	override_background_color_active?: string;
	override_background_color_active_end?: string;
	onClick?: (e: any) => void;
	onChange?: (e: any) => void;
	href?: string;
	ariaCurrent?: AriaCurrentType;
};

export enum EAriaCurrentEnum {
	Page = 'page',
	Step = 'step',
	Location = 'location',
	Date = 'date',
	Time = 'time',
}

export type AriaCurrentType = EAriaCurrentEnum | boolean | undefined;
