'use client';

import React, { useLayoutEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import { useUnit } from 'effector-react';
import { $arenaDataStore, $currentPage, $isSidebarHidden } from '@/app.model';
import classNames from 'classnames';
import { $selectedRadioStore, toggleRadio, $selectedCheckboxStore, toggleCheckbox, THorizontalPills, TPill, PillType } from './model';
import { Pill } from '../Pill';
import { useDeviceDetector } from '@/libs/utils/userAgentContext';
import { buttonBackgroundCalculation } from '@/uikit/helper_funcs/buttonBackgroundCalculation';
import ButtonPrev from '../../features/carousel/button-prev.svg';
import ButtonNext from '../../features/carousel/button-next.svg';
export type TProps = {
  config: THorizontalPills;
  index: number;
  isPillsFromPages?: boolean;
  isSearchPage?: boolean;
};
const HorizontalPills = ({
  config,
  index,
  isPillsFromPages = false,
  isSearchPage
}: TProps) => {
  const {
    pillType,
    horizontalGroup,
    pills,
    title,
    onSelect,
    onClick,
    ariaCurrent
  } = config;
  const SCROLL_BEHAVIOR = 'smooth';
  const selectedRadio = useUnit($selectedRadioStore);
  const selectedCheckboxes = useUnit($selectedCheckboxStore);
  const arenaData = useUnit($arenaDataStore);
  const selectCheckbox = useUnit(toggleCheckbox);
  const selectRadio = useUnit(toggleRadio);
  const {
    isDesktop
  } = useDeviceDetector();
  const isDesktopDetected = isDesktop();
  const isSidebarHidden = useUnit($isSidebarHidden);
  const currentPage = useUnit($currentPage);

  // State to control arrow visibility
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  // Refs for the scroll container and arrow buttons
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // Function to update arrow visibility
  const updateArrows = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const {
        scrollLeft,
        scrollWidth,
        clientWidth
      } = container;

      // Show left arrow if not at the very start
      setShowLeftArrow(scrollLeft > 0);

      // Show right arrow if not at the very end
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth - 1);
    }
  };

  // Update arrows on mount, scroll, and resize
  useLayoutEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    // Initial arrow update
    updateArrows();

    // Event handlers
    const handleScroll = () => updateArrows();
    const handleResize = () => updateArrows();
    container.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    // Cleanup event listeners on unmount
    return () => {
      container?.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Update arrows when the pills data changes
  useLayoutEffect(() => {
    updateArrows();
  }, [pills]);
  const handleRightArrowClick = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const containerWidth = container.clientWidth;
      const scrollAmount = containerWidth * 0.8; // Scroll by 80% of container width

      container.scrollBy({
        left: scrollAmount,
        behavior: SCROLL_BEHAVIOR
      });
    }
  };
  const handleLeftArrowClick = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const containerWidth = container.clientWidth;
      const scrollAmount = containerWidth * 0.8; // Scroll by 80% of container width

      container.scrollBy({
        left: -scrollAmount,
        behavior: SCROLL_BEHAVIOR
      });
    }
  };
  const handleChange = (e: React.MouseEvent<HTMLAnchorElement>, pill: TPill) => {
    switch (pillType) {
      case PillType.Checkbox:
        selectCheckbox({
          group: horizontalGroup,
          label: pill.label
        });
        break;
      case PillType.Radio:
        selectRadio({
          group: horizontalGroup,
          label: pill.label
        });
        break;
      default:
        break;
    }
    if (onSelect) {
      e.preventDefault();
      onSelect(pill);
    }
  };
  const getSelectedState = (pill: TPill) => {
    if (pillType === PillType.Checkbox) {
      return selectedCheckboxes[horizontalGroup]?.has(pill.label);
    }
    if (pillType === PillType.Radio) {
      return selectedRadio[horizontalGroup] === pill.label;
    }
    if (pillType === PillType.Anchor) {
      return pill.isActive;
    }
    return false;
  };
  const containerClass = classNames(styles.horizontalPill, 'horizontalPills');
  const wrapperClass = classNames(styles.horizontalPillWrapper, {
    [styles.pillsWithSidebar]: isDesktopDetected && !isSidebarHidden,
    [styles.pillsFromPages]: isPillsFromPages
  });

  //Style override attributes - If there isn't HGLS specifics, inherit HGL general styles
  const styleOverride: any = {
    ...(arenaData?.layout?.styleOverride?.horizontalGenericLinks ?? {}),
    ...(isSearchPage ? arenaData?.layout?.styleOverride?.horizontalGenericLinksSearch ?? {} : {})
  };

  // Border colors
  const overrideBorderColor = styleOverride?.border_color;
  const overrideBorderColorHover = styleOverride?.border_color_hover;
  const overrideBorderColorActive = styleOverride?.border_color_active;

  // Label colors
  const overrideLabelColor = styleOverride?.label_color;
  const overrideLabelColorHover = styleOverride?.label_color_hover;
  const overrideLabelColorActive = styleOverride?.label_color_active;

  // Background colors
  const overrideBackgroundColor = styleOverride?.background_color;
  const overrideBackgroundColor_end = styleOverride?.background_color_end;
  const overrideBackgroundColorHover = styleOverride?.background_color_hover;
  const overrideBackgroundColorHover_end = styleOverride?.background_color_hover_end;
  const overrideBackgroundColorActive = styleOverride?.background_color_active;
  const overrideBackgroundColorActive_end = styleOverride?.background_color_active_end;
  const finalBackgrounds = buttonBackgroundCalculation({
    override_button_normal_background: overrideBackgroundColor,
    override_button_normal_background_end: overrideBackgroundColor_end,
    override_button_hover_background: overrideBackgroundColorHover,
    override_button_hover_background_end: overrideBackgroundColorHover_end,
    override_button_active_background: overrideBackgroundColorActive,
    override_button_active_background_end: overrideBackgroundColorActive_end,
    override_button_focus_background: overrideBackgroundColorActive ?? '',
    override_button_focus_background_end: overrideBackgroundColorActive_end ?? ''
  });
  const {
    bcgNormal,
    bcgHover,
    bcgActive,
    bcgFocus
  } = finalBackgrounds;
  return <div className={wrapperClass} data-sentry-component="HorizontalPills" data-sentry-source-file="index.tsx">
			{title && <h2 className={`text-20 ark-ui-title ${styles.title}`}>{title}</h2>}
			<div className={containerClass}>
				{isDesktopDetected && showLeftArrow && <button aria-label='Scroll Left' className={classNames(styles.buttonPrev, styles.arrowButton)} onClick={handleLeftArrowClick}>
						<ButtonPrev />
					</button>}
				<div className={styles.pillsContainer} ref={scrollContainerRef}>
					{pills.map((pill, index) => {
          return <div key={pill.label} className='ark-ui-pill-label'>
								<Pill label={pill.label || ''} pillType={pillType} prefix={horizontalGroup} href={pill.href || '#'} target={pill.target || '_self'} leftImg={pill.leftImg} rightImg={pill.rightImg} override_border_color={overrideBorderColor || ''} override_border_color_hover={overrideBorderColorHover || ''} override_border_color_focus={''} override_border_color_active={overrideBorderColorActive || ''} override_label_color={overrideLabelColor || ''} override_label_color_hover={overrideLabelColorHover || ''} override_label_color_focus={''} override_label_color_active={overrideLabelColorActive || ''} override_background_color={bcgNormal || ''} override_background_color_hover={bcgHover || ''} override_background_color_focus={bcgFocus || ''} override_background_color_active={bcgActive || ''} onChange={e => handleChange(e, pill)} selected={getSelectedState(pill)} onClick={e => handleChange(e, pill)} override_use_border={styleOverride?.should_button_use_border !== false} override_use_border_hover={styleOverride?.should_button_use_border_hover !== false} override_use_border_active={styleOverride?.should_button_use_border_active !== false} ariaCurrent={ariaCurrent} />
							</div>;
        })}
				</div>
				{isDesktopDetected && showRightArrow && <button aria-label='Scroll Right' className={classNames(styles.buttonNext, styles.arrowButton)} onClick={handleRightArrowClick}>
						<ButtonNext />
					</button>}
			</div>
		</div>;
};
export default HorizontalPills;